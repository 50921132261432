button {
    cursor: pointer;
    background-color: inherit;
    border: 0;
    margin: 0;
    padding: 0;
}

.header {
    grid-area: headerArea;
    background-color: var(--sivio-orange);
    color: #ffffff;
}

.other-trackers {grid-area: otherTrackersArea;}

.intro {
    grid-area: introArea;
    background-color: var(--sivio-orange);
    color: #ffffff;
}

.wrapper {
    grid-area: wrapperArea;
}

.pg-items {
    grid-area: pgItems;
    margin-top: -50px;
}

.item-details {
    grid-area: itemDetails;
}

.footer {
    grid-area: footerArea;
}


.leftpane {
    grid-area: leftpaneArea;

}



.leftpane {
    display: grid;
    /*grid-template-columns: 1fr 1fr 3fr;*/
    align-content: center;
    width: 100vw;
}

.logo,
.nav-text {
    justify-self: self-start;
    align-self: center;
    grid-area: navTextArea;
}

.item-cont {
    grid-area: itemContArea;
}

.logo-span {
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    padding: 0 15px;
    grid-area: logoSpanArea;
}

.rightpane li {
    list-style: none;
    text-align: right;
}

.introtext {
    grid-area: introtextArea;
    padding: 60px 0 20px 0;
}

.item-1 {
    grid-area: itemOneArea;
}

.item-2 {
    grid-area: itemTwoArea;
}

.item-3 {
    grid-area: itemThreeArea;
}

.item-4 {
    grid-area: itemFourArea;
}

a.item-1,
a.item-2,
a.item-3,
a.item-4 {
    text-decoration: none;
}

/*.item-cont button{padding: 0 20px;}*/


.left-desc-pane {
    grid-area: leftDescPane;
}

.right-desc-pane {
    grid-area: rightDescPane;
    background-color: var(--sivio-orange);
    color: #ffffff;
    z-index: 2;
}


.pg-items {}

.item-box {
    display: grid;
    text-align: center;
    justify-content: center;
    align-content: space-between;
    height: 195px;
}

.item-box p {
    margin: 0;
    display: none;
}

.item-box span {
    font-family: 'Bebas';
    font-size: 24px;
    line-height: 32px;
    color: var(--sivio-orange);
    align-items: center;
    display: flex;
    justify-content: center;
}

.item-box h3 {
    font-family: 'TT Commons';
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: var(--sivio-orange);
    margin: 0;
}

a.left-desc-item {
    color: var(--sivio-grey);
    text-decoration: none;
}

.left-desc-item span.title {
    text-decoration: underline;
    padding-left: 7px;
    text-align: left;
}

/*Item Number */
button span.step {
    background: #ffffff;
    -moz-border-radius: 0.8em;
    color: var(--sivio-grey);
    font-family: 'TT Commons';
    font-size: 18px;
    line-height: 36px;
    display: grid;
    align-items: center;
    justify-items: center;
    font-weight: bold;
    line-height: 1.5em;
    width: 1.6em;
    border: 1px solid var(--sivio-grey);
    box-sizing: border-box;
    border-radius: 50%;
    height: 1.6em;
    justify-self: left;
    text-decoration: none;
}

.left-desc-item {
    display: inline-flex;
    align-items: center;
    padding: 7px 0;
}

button.left-desc-item:hover span.step {
    background: #ffffff;
    -moz-border-radius: 0.8em;
    color: var(--sivio-orange);
    font-family: 'TT Commons';
    font-size: 18px;
    line-height: 36px;
    display: grid;
    align-items: center;
    justify-items: center;
    font-weight: bold;
    line-height: 1.5em;
    width: 1.6em;
    border: 1px solid var(--sivio-orange);
    box-sizing: border-box;
    border-radius: 50%;
    height: 1.6em;
    justify-self: left;
}

button.left-desc-item:hover span.title {
    display: grid;
    grid-template-columns: auto 1fr;
    color: var(--sivio-orange);
}

button.left-desc-item:hover .title span.svg {
    width: 40px;
    height: 15px;
    background-image: url('./assets/svg/ic_Arrow.svg');
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 15px;
}

/* end item number */


.left-desc-pane-h1 {
    grid-area: leftdescpaneh1;
    color: var(--sivio-orange);
    font-size: 36px;
    line-height: 41px;
}

.left-desc-pane-item {
    grid-area: leftDescPaneItem;
}

.left-desc-pane {
    /*
      display: grid;
      
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      grid-template-areas: 
          "left-desc-pane-h1 left-desc-pane-h1 left-desc-pane-h1 left-desc-pane-h1"
          "left-desc-pane-item left-desc-pane-item left-desc-pane-item left-desc-pane-item";
    
    grid-template-columns: 1fr;
    */
    padding: 50px 1%;
    ;
}

.left-pane-item-holder {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.border-right {
    border-right: 1px solid rgba(51, 63, 72, 0.16);
}

.selected {
    display: grid;
    background-color: var(--sivio-grey);
    margin: -15px 0px;
    color: #ffffff !important;
    padding: 0 20px;
    border-bottom: 4px solid var(--sivio-orange);
}

.selected::after {
    position: relative;
    content: '';
    height: 15px;
    width: 15px;
    background-color: var(--sivio-white);
    border: 4px solid var(--sivio-orange);
    bottom: -24px;
    margin-left: 50%;
    border-bottom-color: transparent;
    border-right-color: transparent;
    transform: translateX(-50%) rotate(45deg);
}

.selected h3,
.selected span {
    color: #ffffff !important;
}

.selected span {
    padding-top: 15px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.selected-svg {
    display: none;
}

.selected p {
    display: block;
}

.selected .selected-svg {
    display: block;
    width: calc(100.5% + 40px);
    position: relative;
    bottom: -1px;
    margin-left: -20px;
    margin-right: -20px;
}

.overlay {
    position: absolute;
}

.left-desc-item:hover button {
    color: var(--sivio-orange) !important;
}

.popup {
    font-size: 2em;
    color: var(--sivio-white);
    align-items: center
}
.popupBody {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.popupBody::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}
.tabContent {
    padding-bottom: 100px;
}
.popupBody h4 {
    text-transform: capitalize;
    font-size: 24px;
    line-height: 1.9em;
}

.popupBody h2 {
    font-family: 'Kepler Std';
    font-weight: 400;
    line-height: 1.9em;
}

.tabButtons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0;
}

.tabButtons button {
    padding: 10px 7px;
    display: grid;
    text-transform: capitalize;
    font-size: 28px;
    color: var(--sivio-light-grey);
    font-family: 'Kepler Std';
    font-style: normal;
    border-bottom: 1px solid var(--sivio-light-grey);
}

.tabButtons button::after {
    position: relative;
    content: '';
    height: 15px;
    width: 15px;
    background: transparent;
    border: 0;
    bottom: -19px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
}

.tabButtons button.active {
    color: var(--sivio-white);
    border-bottom: 1px solid var(--sivio-white);
}

.tabButtons button.active::after {
    position: relative;
    content: '';
    height: 15px;
    width: 15px;
    background-color: var(--sivio-grey);
    border: 1px solid var(--sivio-white);
    bottom: -19px;
    left: 50%;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: translateX(-50%) rotate(45deg);
}

.tabContent h4 {
    border-bottom: 1px solid var(--sivio-light-grey);
    padding: 4px;
    font-family: 'Kepler Std';
    font-style: normal;
}

.tabSimpleh4 {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.tabContent h5 {
    padding: 4px;
    font-family: 'Kepler Std';
    font-style: normal;
    font-weight: 400;
    margin: 0;
    font-size: 22px;
}

.h4SubContent {
    padding-left: 50px;
}

.h4SubContent h5 {
    display: grid;
    grid-template-columns: 3fr 1fr;
    padding-left: 60px;
}

.upArrow {
    height: 20px;
}

.rotateArrow {
    transform: rotate(180deg);
}
.loaderContainer svg{
    top: 40%;
    left: 40%;
    position: absolute;
}
.hidden {display: none;}

/* Style The Dropdown Button */
.dropbtn {
    color: white;
    padding: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .downarrow {height: 20px;}
  

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .smScreen {display: block;}
    .otherTrackersLink {display: none;}
    .container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 65px calc(100vh - 65px);
        margin: 0 auto;
        grid-gap: 0px;
        height: 100vh;
        grid-template-areas: "headerArea""wrapperArea";
    }

    .popup {
        width: 100vw !important;
        min-width: unset !important;
    }
    .tabButtons button {
        font-size: 16px;
    }
    .popupBody h4 {
        font-size: 18px;
    }
    .tabContent h5 {
        font-size: 16px;
    }
    .inflationContainer {
        font-size: 0.5em !important;
        display: grid;
        grid-template-columns: 1fr !important;
    }

    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
            "introArea"
            "pgItems"
            "itemDetails";
        grid-template-rows: calc((100vh - 65px) / 3) calc(100vh - 65px - ((100vh - 65px) / 3)) auto;
    }

    .item-cont {
        display: grid;
        grid-template-columns: 1fr;
        color: var(--sivio-orange);
        background: #EEEEEE;
        grid-auto-rows: calc((100vh - 65px - ((100vh - 65px) / 3)) /3);
        grid-template-areas:
        "itemOneArea"
        "itemTwoArea"
        "itemThreeArea";
    }

    .selected {
        margin: 0;
        padding: 0;
    }
    .selected::after {
        display: none;
    }
    .selected span {
        padding-top: 0;
    }
    .item-box {
        height: unset;
        justify-content: stretch;
    }
    .pg-items {
        margin: 0;
    }

    .intro {
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    .introtext {padding: 0 20px;}

    .item-details {
        padding: 5px;

    }
    .leftpane {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .left :nth-child() {
        padding: 10px;
    }

    .header {
        display: flex;
        align-items: center;
        padding: 30px 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }

    /* BUtton on small screens */
    .item-cont button {
        display: grid;
        background-color: var(--sivio-grey);
        color: #ffffff !important;
        border-bottom: 4px solid var(--sivio-orange);
    }
    
    .item-cont button::after {
        display: none;
        position: relative;
        content: '';
        height: 15px;
        width: 15px;
        background-color: var(--sivio-white);
        border: 4px solid var(--sivio-orange);
        bottom: -28px;
        margin-left: 50%;
        border-bottom-color: transparent;
        border-right-color: transparent;
        transform: translateX(-50%) rotate(45deg);
    }
    
    .item-cont button h3,
    .item-cont button span {
        color: #ffffff !important;
    }
    
    .item-cont button span {
        align-items: center;
        display: flex;
        justify-content: space-around;
    }
    item-cont button h3 {
        padding-top: 15px;
    }
    
    .selected-svg {
        display: none;
    }
    
    .item-cont button p {
        display: block;
    }
    
    .item-cont button .selected-svg {
        display: block;
        width: calc(100.5% + 40px);
        position: relative;
        bottom: -1px;
        margin-left: -20px;
        margin-right: -20px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    .smScreen {display: none;}
    .otherTrackersLink {display: block;}

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 75px auto 150px;
        grid-template-areas:
            "headerArea headerArea headerArea headerArea headerArea"
            "wrapperArea wrapperArea wrapperArea wrapperArea wrapperArea"
            "footerArea footerArea footerArea footerArea footerArea";
        /*grid-template-columns: 30% 20% 50% 
        grid-uto-rows: minmax[200px, auto]*/
        margin: 0 auto;
        grid-gap: 0px;
        height: 100vh;
    }

    .wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 300px 190px 300px;
        grid-template-areas:
            "introArea introArea introArea introArea introArea"
            "pgItems pgItems pgItems pgItems pgItems"
            "itemDetails itemDetails itemDetails itemDetails itemDetails";
        margin: 0 auto;
        grid-gap: 0px;
    }

    .header {
        display: grid;
        /*grid-template-columns: repeat(4, 1fr);*/
        grid-template-rows: auto;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        grid-template-columns: repeat(6, 1fr);
        align-items: center;
        justify-items: center;
        grid-template-areas: "logoSpanArea navTextArea . . otherTrackersArea .";
    }
    .header p, .header button { font-size: 20px;}

    .pg-items {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto;
        grid-template-areas: "itemContArea itemContArea itemContArea itemContArea . .";
        height: 240px;
    }

    .intro {
        padding: 0 5.69%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto;
        grid-template-areas: "introtextArea introtextArea introtextArea introtextArea introtextArea introtextArea . . . . . ."
    }

    .item-cont {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "itemOneArea itemTwoArea itemThreeArea";
        color: var(--sivio-orange);
        background: #EEEEEE;
        padding: 15px 0;
    }

    .item-details {
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-template-rows: auto;
        grid-template-areas: ". leftDescPane leftDescPane leftDescPane leftDescPane leftDescPane leftDescPane leftDescPane leftDescPane leftDescPane leftDescPane leftDescPane leftDescPane leftDescPane leftDescPane rightDescPane rightDescPane rightDescPane rightDescPane rightDescPane rightDescPane rightDescPane rightDescPane rightDescPane"

    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}