@font-face {
  font-family: "TT Commons";
  src: url("./assets/fonts/TT.Commons/TTCommons-Regular.ttf");
}
@font-face {
  font-family: 'Kepler Std';
  src: url('./assets/fonts/transfonter/KeplerStd-Disp.woff2') format('woff2'),
      url('./assets/fonts/transfonter/KeplerStd-Disp.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
:root {
  --sivio-orange: #F15C27;
  --sivio-grey: #333F48;
  --sivio-white: #ffffff;
  --sivio-light-grey: #7D98AD;
}
body {
    font-family: "TT Commons";
    font-weight: 400;
    font-size: 16px;
    color: var(--sivio-grey);
    margin:0;
}
h2 {
    font-family: 'TT Commons';
    font-size: 36px;
    line-height: 41px;
    font-weight: 400;
    /* White */
    color: #FFFFFF;
}
h4 {font-weight: 400;}
.m-0 {margin:0 !important;}
.p-0 {padding:0 !important;}

:focus {
  outline: none !important;
}
/*box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.7)*/